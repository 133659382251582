
    @media (min-width: 1200px) {
        .table-container{
            height: 70vh;
        }
    }
    @media (min-width: 1920px) {
        
        .table-container{
            height: 76vh;
        }
    }

.sticky-header-left{
    left: 0;
    top: 0;
    position: -webkit-sticky;
    position: sticky;
    /* background: #E3F2FD; */
    background: linear-gradient(to left,#e0e0e0, #e0e0e0 1px, #E3F2FD 1px, #E3F2FD 100%);
    z-index: 8;
}


.sticky-header-right{
    right: 0;
    top: 0;
    position: -webkit-sticky;
    position: sticky;
    background: #E3F2FD;
    min-width: 17em;
    background: linear-gradient(to right,#e0e0e0, #e0e0e0 1px, #E3F2FD 1px, #E3F2FD 100%);
    z-index: 8;
}

.sticky-header{
    left: 0;
    top: 0;
    position: -webkit-sticky;
    position: sticky;
    background: #E3F2FD;
    z-index: 7;
}

.sticky-group-header-left{
    left: 0;
    position: -webkit-sticky;
    position: sticky;
    background: grey;
    z-index: 6;
    
    
}
.sticky-group-header-left div{
    color: white;
    font-weight: 700
    
}

.sticky-group-header-right{
    right: 0;
    position: -webkit-sticky;
    position: sticky;
    background: grey;
    z-index: 6;
}

.sticky-group-header{
    background: grey;
    z-index: 6;
}

.sticky-row-left{
    left: 0;
    position: -webkit-sticky;
    position: sticky;
    
    
}

.sticky-row-left .required:before {
    content:"* ";
    color: red;
}

.sticky-row-left.even {
    /* background-color:#f9f9f9 */
    background: linear-gradient(to left,#e0e0e0, #e0e0e0 1px, #f9f9f9 1px, #f9f9f9 100%);
}
.sticky-row-left.odd {
    /* background-color:#ffffff */
    background: linear-gradient(to left,#e0e0e0, #e0e0e0 1px, #ffffff 1px, #ffffff 100%);
}

.sticky-row-left.highlight {
    background-color:#EDE5FD
}

.sticky-row-right{
    right: 0;
    position: -webkit-sticky;
    position: sticky;
    

}
.sticky-row-right.even {
    /* background-color:#f9f9f9 */
    background: linear-gradient(to right,#e0e0e0, #e0e0e0 1px, #f9f9f9 1px, #f9f9f9 100%);
}
.sticky-row-right.odd {
    /* background-color:#ffffff */
    background: linear-gradient(to right,#e0e0e0, #e0e0e0 1px, #ffffff 1px, #ffffff 100%);
}

.sticky-row-right.highlight {
    /* background-color:#EDE5FD; */
    background: linear-gradient(to right,#e0e0e0, #e0e0e0 1px, #EDE5FD 1px, #EDE5FD 100%);
}

.row.even {
    background-color:#f9f9f9
}
.row.odd {
    background-color:#ffffff
}

.row.highlight {
    background-color:#EDE5FD
}

.hide-history{
    display: none;
}
.show-history{
    display: bloak;
}
.switch-button{
    background-color: blue;
}
.close-switch {
    transform: rotate(180deg)
}