.user-name {
    text-transform: capitalize;
}
.user-title {
    text-transform: capitalize;
}

.profile-image {
    font-family: Arial, Helvetica, sans-serif;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #FFEBEE;
    text-align: center;
    line-height: 40px;
    font-size: 15px;
    font-weight: bold;
    color: #000000;
    text-transform: uppercase;
}